.form .form-row .form-row-fields {
  .insurance-question label {
    padding: 12px;
    border: .5px solid rgba(0,0,0,.42);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .radio-wrapper {
      border: 0.5px solid #000;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      position: relative;
      overflow: hidden;
      top: 0;
      display: flex;

      input:checked ~ .radio-indicator {
        background-color: #000000;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('/assets/img/check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    > span {
      width: calc(100% - 30px);
      line-height: 18px;
      font-weight: bold;
    }

    &.with-success {
      background-color: rgba(0,153,0,.4);
    }
  }

  .insurance-yes {
    margin-top: 20px;
  }

  .insurance-terms {
    .radio-wrapper {
      top: 0;
    }
  }
}

.travel-insurance-answer.hidden {
  display: none!important;
}

.insurance-start-date, .insurance-end-date {
  align-self: flex-end;
  display: block;

  label {
    font-size: 16px !important;
    line-height: 24px;
  }
}

.insurance-end-date ~ .clearfix {
  @media (min-width: 992px) {
    display: none;
  }
}

form .form-row.insurance-container {
  .form-row-fields {
    display: block;
    flex-wrap: wrap;
  }

  .form-row-fields .row, .validation {
    display: block;
    padding-left: 0;

    .fields-column {
      float: initial;
    }
  }

  .insurance-question label {
    cursor: pointer;

    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.insurance-additional-description {
  float: left;
  margin-bottom: 10px;
  max-width: 50%;
}

.background-gray {
  background-color: #ddd !important;
  border: none !important;
}

.form .form-row .form-row-fields .fields-column {
  &.insurance-start-date, &.insurance-end-date {
    margin: 16px 0;
    float: right;
  }
}

.insurance-mobile-content {
  margin-top: 12px;
  margin-bottom: 12px;

  ul {
    margin-bottom: 1rem;

    li {
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .insurance-links {
    display: flex;
    font-size: 16px;
    color: #004288;
    padding-left: 10px;
    padding-right: 36px;
    text-decoration: underline;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .insurance-expand {
    display: none;
  }
  .insurance-mobile-content {
    display: block !important;
  }
}

.insurance-expand img {
  margin-left: 6px;
}

.insurance-modal {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  outline: none;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, .3);
  .modal-dialog {
    min-width: 800px;
    z-index: 99999;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 800px) {
      min-width: 100%;
    }
  }
  .modal-spacing {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-content {
    padding: 30px 70px;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 50%);
    max-width: 780px;
    width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    transform: none;
    .insurance-modal-close {
      width: 40px;
      cursor: pointer;
    }

    @media (max-width: 600px) {
      padding: 30px 15px;

      .insurance-modal-close {
        width: 20px;
      }

      img {
        max-width: 200px
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 40px;
      color: black;
      margin: 0;
    }
  }
  .content {
    margin-top: 15px;
    @media (max-width: 700px) {
      margin-top: 30px;
    }
    .table {
      margin-top: 50px;
      .title {
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        padding: 15px 15px;
        align-items: center;
        background-color: #EDF6FF;
        font-size: 20px;
        font-family: "Lato", Fallback, sans-serif;
      }
      .cel {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        font-family: "Lato", Fallback, sans-serif;
        color: #707070;
        font-size: 20px;
        span {
          max-width: 50%;

          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
        .value {
          width: 100%;
          text-align: center;
          font-weight: 700;
          @media (max-width: 600px) {
            padding-left: 15px;
            text-align: end;
          }
        }
        &.border {
          margin-left: -15px;
          margin-right: -15px;
          padding: 15px;
          border-bottom: 1px solid #ADADAD;
        }
      }
      .regulations {
        display: flex;
        margin-top: 30px;
        font-family: "Lato", Fallback, sans-serif;
        font-size: 18px;
        color: #004288;
        a {
          margin-right: 30px;
        }
      }
      .small {
        margin-top: 20px;
        font-size: 14px;
        font-family: "Lato", Fallback, sans-serif;
        color: black;
      }

      @media (max-width: 800px) {
        .regulations {
          flex-direction: column;

          a {
            margin-top: 8px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

#insurance-modal-open {
  background: none;
  border: none;
  color: #337ab7;
  font-size: 16px;
  outline: none;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

#insurance-terms-text {
  float: right;
  width: calc(100% - 24px);
  margin-left: 4px;
  overflow: auto;
  margin-bottom: 15px;
}

.form .form-row.insurance-terms-row {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.column-md-50 {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 992px) {
  .column-md-50 {
    width: 50%;
  }
}

.insurance-text {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
}

.text-small {
  font-weight: bold;
  font-size: 16px;

  #insurance-price {
    font-weight: bold;
  }
}

.insurance-text .text-heading > span {
  font-size: 15px;
}
